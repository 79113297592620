// import ContactForm from "@components/forms/ContactForm"
import MailIcon from "@components/icons/MailIcon"
import PhoneIcon from "@components/icons/PhoneIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import useBrandInfo from "@hooks/dataHooks/useBrandInfo"
import useDeviceMedia from "@hooks/useDeviceMedia"
import { getWhatsAppURL, gtagReportConversion } from "@lib/utils"
import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import "./ContactFomModule.scss"
import loadable from "@loadable/component"
import useInteractive from "@hooks/useInteractive"
import GGFXImage from "@components/elements/GGFXImage"
import FsLightbox from "fslightbox-react"

const ContactForm = loadable(() => import("@components/forms/ContactForm"))
const {
  FormCdnUrl,
} = require("@starberry/gatsby-theme-utils/Common/Stb-Jamstack-Ggfxclient/Utility")
const BContactFomModule = ({ module, masterplan_image, ggfx_results, strapiID}) => {
  const { hasInteracted } = useInteractive()


  if (!module) return null
  const imageUrls = [FormCdnUrl(masterplan_image?.url)]
  const [toggler, setToggler] = React.useState(false)

  return (
    <div
      className={clsx("contact-form-wrapper ", "section-p", masterplan_image?.url && "blue bc-block")}
    >
      <Container className={clsx("contact-form-container ")}>
        <div className="form-section">{hasInteracted && <ContactForm title={module.title} heading={module.heading} />}</div>
        <div className="image-section">
        <GGFXImage
          ImageSrc={masterplan_image}
          altText="banner-bg"
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename={"area-guide.masterplan_image.image"}
          strapiID={strapiID}
        />
        <button
                className="button button-white"
                onClick={() => {
                  setToggler(!toggler)
                }}
              ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M2.5 2.5V5.5M2.5 2.5H5.5M2.5 2.5L6 6M2.5 13.5V10.5M2.5 13.5H5.5M2.5 13.5L6 10M13.5 2.5L10.5 2.5M13.5 2.5V5.5M13.5 2.5L10 6M13.5 13.5H10.5M13.5 13.5V10.5M13.5 13.5L10 10" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> Enlarge</button>
        </div>
        <FsLightbox
        toggler={toggler}
        sources={imageUrls}
        type="image"
      />
      </Container>
    </div>
  )
}

export default BContactFomModule
