import React, { useEffect, useMemo, useState } from "react"
import "./DubaiCommunities.scss"
import { Accordion, Container } from "react-bootstrap"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Tab, Tabs } from "@components/elements/Tabs/Tabs"
import loadable from "@loadable/component"

import {
  offplanResultsSaleURL,
  propertiesRentURL,
  propertiesSaleURL,
} from "@lib/urls"
const Animation = loadable(() => import("@components/elements/Animation"))
const DubaiCommunities = ({ module }) => {
  const KEYS = useMemo(() => {
    return {
      sale: "for-sale",
      rent: "for-rent",
      offplan: "off-plan",
    }
  }, [])

  const [propertyURL, setPropertyURL] = useState(propertiesSaleURL)
  const [activeKey, setActiveKey] = useState(KEYS.sale)

  useEffect(() => {
    if (activeKey === KEYS.sale) {
      setPropertyURL(propertiesSaleURL)
    } else if (activeKey === KEYS.rent) {
      setPropertyURL(propertiesRentURL)
    } else {
      setPropertyURL(offplanResultsSaleURL)
    }
  }, [activeKey])

  const data = useStaticQuery(graphql`
    query {
      strapiGlobalModule {
        dubai_communities {
          name
          slug
        }
      }
    }
  `)

  const dubaiCommunities = data?.strapiGlobalModule?.dubai_communities
  if (!module || !dubaiCommunities) return null

  return (
    <Animation animateInType="up" className="dubai-communities-wrap section-p">
      <Container className="dubai-communities-container">
        <h2 className="title">{module?.title}</h2>
        <div className="dubai-communities-tab-section d-none d-md-block">
          <Tabs
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            className="tab-header"
          >
            <Tab eventKey={KEYS.sale} title="For Sale">
              For Sale
            </Tab>
            <Tab eventKey={KEYS.rent} title="For Rent">
              For rent
            </Tab>
            <Tab eventKey={KEYS.offplan} title="Off Plan">
              Off Plan
            </Tab>
          </Tabs>
          <div className="tab-body">
            {dubaiCommunities.map((community) => (
              <Link
                to={`${propertyURL}in-${community.slug}/`}
                key={community.slug}
              >
                {community.name}
              </Link>
            ))}
          </div>
        </div>
          <Accordion className="dubai-communities-tab-section d-block d-md-none">
              <Accordion.Item eventKey={"buy"}>
                <Accordion.Header onClick={() => setPropertyURL(propertiesSaleURL)} className="title">For Sale</Accordion.Header>
                <Accordion.Body className="cta-section">
                <div className="tab-body">
                  {dubaiCommunities.map((community) => (
                    <Link
                      to={`${propertyURL}in-${community.slug}/`}
                      key={community.slug}
                    >
                      {community.name}
                    </Link>
                  ))}
                </div>
                </Accordion.Body>
              </Accordion.Item> 
              <Accordion.Item eventKey={"rent"}>
                <Accordion.Header onClick={() => setPropertyURL(propertiesRentURL)} className="title">For Rent</Accordion.Header>
                <Accordion.Body className="cta-section">
                <div className="tab-body">
                  {dubaiCommunities.map((community) => (
                    <Link
                      to={`${propertyURL}in-${community.slug}/`}
                      key={community.slug}
                    >
                      {community.name}
                    </Link>
                  ))}
                </div>
                </Accordion.Body>
              </Accordion.Item>   
              <Accordion.Item eventKey={"offplan"}>
                <Accordion.Header onClick={() => setPropertyURL(offplanResultsSaleURL)} className="title">Off Plan</Accordion.Header>
                <Accordion.Body className="cta-section">
                <div className="tab-body">
                  {dubaiCommunities.map((community) => (
                    <Link
                      to={`${propertyURL}in-${community.slug}/`}
                      key={community.slug}
                    >
                      {community.name}
                    </Link>
                  ))}
                </div>
                </Accordion.Body>
              </Accordion.Item>        
          </Accordion>
          
      </Container>
    </Animation>
  )
}

export default DubaiCommunities
