import TeamCard from "@components/TeamCard/TeamCard"
import useTeams from "@hooks/dataHooks/useTeams"
import React from "react"
import "./TeamsSlider.scss"
import SliderTeamModule from "@components/SliderModule/SliderTeamModule"

const CommunityTeamsSlider = ({ heading, area, teams }) => {
  const allTeams = useTeams()
  if (!module) return null


  const teamsToDisplay = allTeams
  const filteredTeams = teams && teams?.length > 0 ? teams : teamsToDisplay.filter(team => {
    // Check if the community field in team contains a matching slug or name
      return team?.community?.strapi_json_value?.some(value =>
        value.includes(area)
      );
   
  });

  const sliderChildren =  filteredTeams
    ?.slice(0, 6)
    .map((team) => <TeamCard key={team.id} team={team} isTeam/>)

  return (
    sliderChildren?.length > 0 &&
    <SliderTeamModule
      title={heading}
      // title={title}
      // ctaText={cta_text}
      sliderChildren={sliderChildren}
      wrapperClassname="teams-slider-wrap"
      size="xl"
      sectionClassname="section-m"
    />
  )
}

export default CommunityTeamsSlider
