import React from "react"
import "./AreaguideInfo.scss"
import { Accordion, Container } from "react-bootstrap"
import { parseHTML, scrollToElement } from "@lib/utils"
import { isEmpty } from "lodash"

const AreaguideInfo = ({ areaguide }) => {
  if (!areaguide) {
    return null
  }

  const { content, title } = areaguide

  const firstPara = `${content?.data?.content?.split("</p>")?.[0]}</p>`
  const restPara = content?.data?.content?.split("</p>")?.slice(1)?.join("</p>")
  return (
    <div className="area-info-wrap section-m">
      <Container className="area-info-container">
        <div className="content-section">
          <p className="heading">about {title}</p>
          <div className="content">
            <div className="left-content">{parseHTML(firstPara)}</div>
            <div className="right-content">{parseHTML(restPara)}</div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AreaguideInfo

export const AreaguideMoreInfo = ({ more_info, title }) => {
  if (isEmpty(more_info)) return null

  return (
    <div className="areaguide-moreinfo-wrap section-p">
      <Container className="faq-section areaguide-accordian-section">
        <h2 className="title">{`Useful Information about ${title}`}</h2>
        <Accordion defaultActiveKey={more_info?.[0]?.id}>
          {more_info?.map((faq, index) => (
            <Accordion.Item
              eventKey={faq.id}
              key={faq.id}
              id={`accordian_item-${index}`}
            >
              <Accordion.Header
                onClick={(e) => {
                  // Get the previous accordion item by its ID
                  const prevAcc = document.getElementById(
                    `accordian_item-${index - 1}`
                  )

                  // Get the height of the previous accordion item, or default to 0 if it doesn't exist
                  const prevHeight = prevAcc?.offsetHeight || 0

                  // Check if the height of the previous accordion item is greater than the Y-coordinate of the click event
                  const isPrevLarger = prevHeight > e.clientY

                  if (isPrevLarger) {
                    // If the previous accordion item is taller than the click position, calculate a new Y-coordinate
                    const y = e.clientY - 12

                    setTimeout(() => {
                      // After a delay of 400 milliseconds, scroll the page to bring the clicked element into view with an offset of y
                      scrollToElement(e.target, y, true)
                    }, 400)
                  }
                }}
              >
                {faq.question}
              </Accordion.Header>
              <Accordion.Body>
                {parseHTML(faq.answer?.data?.answer || faq.answer)}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
    </div>
  )
}
