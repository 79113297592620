import { sort } from "@lib/utils"
import { graphql, useStaticQuery } from "gatsby"

const useDevelopers = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiDeveloper(
        sort: { fields: rank, order: ASC }
        filter: { publish: { eq: true } }
      ) {
        nodes {
          ...developerFragment
        }
      }
    }
  `)

  const developers = data?.allStrapiDeveloper?.nodes

  const sortedDevelopers = developers?.sort((a, b) => sort(a.rank, b.rank))

  return sortedDevelopers
}

export default useDevelopers
