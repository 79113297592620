/* eslint-disable import/no-import-module-exports */
import { resiPropertyTypeOptions } from "@components/SearchResults/SearchFilters/filterOptions"
import Animation from "@components/elements/Animation"
import { Tab, Tabs } from "@components/elements/Tabs/Tabs"
import { propertiesSaleURL } from "@lib/urls"
import { Link } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import { Container } from "react-bootstrap"
import "./DubaiCommunities.scss"

const AreaguideProperties = ({ areaguide }) => {
  const { slug, title, custom_property_slug } = areaguide
  const KEYS = useMemo(() => {
    return {
      type: "for-sale",
    }
  }, [])

  const [propertyURL, setPropertyURL] = useState(propertiesSaleURL)
  const [activeKey, setActiveKey] = useState(KEYS.type)

  useEffect(() => {
    if (activeKey === KEYS.type) {
      setPropertyURL(propertiesSaleURL)
    }
  }, [activeKey])

  return (
    <Animation animateInType="up" className="dubai-communities-wrap section-p">
      <Container className="dubai-communities-container">
        <h2 className="title">{`Explore Property in ${title}`}</h2>
        <div className="dubai-communities-tab-section">
          <Tabs
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            className="tab-header"
          >
            <Tab eventKey={KEYS.type}>Properties by Type</Tab>
          </Tabs>
          <div className="tab-body">
            {resiPropertyTypeOptions.map(
              (type) =>
                !!type.value && (
                  <Link
                    to={`/buy/${type.value ? type.value : propertyURL}-for-sale/in-${
                      custom_property_slug || slug
                    }/`}
                    key={type.value}
                  >
                    {type.label}
                  </Link>
                )
            )}
          </div>
        </div>
      </Container>
    </Animation>
  )
}

export default AreaguideProperties
